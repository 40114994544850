import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  Token,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";

import { WalletContextState } from "@solana/wallet-adapter-react";

import {
  Connection,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  Transaction,
  TransactionInstruction,
} from "@solana/web3.js";
import {
  Metaplex,
  Signer,
  token,
  walletAdapterIdentity,
} from "@metaplex-foundation/js";
import { NftsToMerge } from "./merging/MintPopup";
import { BURNER_WALLET, KEEP_WALLET } from "./merging/MergingService";

export const CANDY_MACHINE_PROGRAM = new PublicKey(
  "cndyAnrLdpjq1Ssp1z8xxDsB8dxe7u4HL5Nxi2K5WXZ" //'cndyAnrLdpjq1Ssp1z8xxDsB8dxe7u4HL5Nxi2K5WXZ',
);

const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID = new PublicKey(
  "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
);

const TOKEN_METADATA_PROGRAM_ID = new PublicKey(
  "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"
);

export const shortenAddress = (address: string, chars = 4): string => {
  return `${address.slice(0, chars)}...${address.slice(-chars)}`;
};

export async function sendNftMetaplex(
  mintPubKey: PublicKey,
  fromPubKey: PublicKey,
  toPubKey: PublicKey,
  feePayerPubKey: PublicKey,
  connection: Connection
): Promise<Transaction | undefined> {
  const feePayer: Signer = {
    publicKey: fromPubKey,
    signTransaction: async (tx) => tx,
    signMessage: async (msg) => msg,
    signAllTransactions: async (txs) => txs,
  };

  const metaplex = new Metaplex(connection);
  metaplex.use(
    walletAdapterIdentity({
      publicKey: feePayerPubKey,
      signTransaction: async (tx) => tx,
    })
  );

  const nft = await metaplex.nfts().findByMint({ mintAddress: mintPubKey });

  const txBuilder = metaplex
    .nfts()
    .builders()
    .transfer({
      nftOrSft: nft,
      fromOwner: fromPubKey,
      toOwner: toPubKey,
      amount: token(1),
      authority: feePayer,
    });

  const blockhash = await connection.getLatestBlockhash();
  const tx = txBuilder.toTransaction(blockhash);
  return tx;
}

export async function transfer(
  nftsToMerge: NftsToMerge,
  walletPubKey: PublicKey,
  walletState: WalletContextState,
  connection: Connection
): Promise<any> {
  console.log("update");
  const instructions: TransactionInstruction[] = [];
  const metaplexInstructions: TransactionInstruction[] = [];

  let count = 0;
  const tokenMintAddresses = [];
  if (!nftsToMerge.tokenToKeep || !nftsToMerge.tokenToBurn) {
    return "Tried to send nfts but one of the tokens that we sent was null";
  }
  if (!walletState) {
    return "wallet state was null when sending nfts";
  }
  tokenMintAddresses.push(nftsToMerge.tokenToKeep, nftsToMerge.tokenToBurn);
  for (const tokenMint of tokenMintAddresses) {
    const mintPublicKey = new PublicKey(tokenMint!.mint);

    const fromAssociatedTokenAccountKey = await findAssociatedTokenAddress(
      walletPubKey,
      mintPublicKey
    );

    let destPublicKey;

    if (count === 1) {
      destPublicKey = new PublicKey(BURNER_WALLET);
    } else {
      destPublicKey = new PublicKey(KEEP_WALLET);
    }

    // Get the derived address of the destination wallet which will hold the custom token
    const associatedDestinationTokenAddr = await findAssociatedTokenAddress(
      destPublicKey,
      mintPublicKey
    );

    // const associatedDestinationTokenAddr = await Token.getAssociatedTokenAddress(
    //   mintToken.associatedProgramId,
    //   mintToken.programId,
    //   mintPublicKey,
    //   destPublicKey
    // );

    // const fromTokenAccount = await connection.getAccountInfo(fromAssociatedTokenAccount);
    const receiverAccount = await connection.getAccountInfo(
      associatedDestinationTokenAddr
    );

    // if (!fromTokenAccount){
    //     console.log("user didnt have an ATA of the mint they're trying to send?");
    //     return;
    // }

    if (receiverAccount === null) {
      instructions.push(
        Token.createAssociatedTokenAccountInstruction(
          ASSOCIATED_TOKEN_PROGRAM_ID,
          TOKEN_PROGRAM_ID,
          mintPublicKey,
          associatedDestinationTokenAddr,
          destPublicKey,
          walletPubKey
        )
      );
    }

    // instructions.push(
    //   Token.createTransferInstruction(
    //     TOKEN_PROGRAM_ID,
    //     fromAssociatedTokenAccountKey,
    //     associatedDestinationTokenAddr,
    //     wallet.publicKey,
    //     [],
    //     amount
    //   )
    // );
    instructions.push(
      Token.createTransferCheckedInstruction(
        TOKEN_PROGRAM_ID,
        fromAssociatedTokenAccountKey,
        mintPublicKey,
        associatedDestinationTokenAddr,
        walletPubKey,
        [],
        1,
        0
      )
    );
    const tx = await sendNftMetaplex(
      mintPublicKey,
      walletPubKey,
      destPublicKey,
      walletPubKey,
      connection
    );
    metaplexInstructions.push(...tx!.instructions);
    count++;
  }

  instructions.push(
    SystemProgram.transfer({
      fromPubkey: walletPubKey,
      toPubkey: new PublicKey(KEEP_WALLET),
      lamports: LAMPORTS_PER_SOL * 0.01,
    })
  );

  metaplexInstructions.push(
    SystemProgram.transfer({
      fromPubkey: walletPubKey,
      toPubkey: new PublicKey(KEEP_WALLET),
      lamports: LAMPORTS_PER_SOL * 0.01,
    })
  );

  console.log(metaplexInstructions);
  console.log(instructions);

  const transaction = new Transaction().add(...metaplexInstructions);
  transaction.feePayer = walletPubKey;
  //   const recentBlockhash = await connection.getRecentBlockhash("finalized");
  //   console.log(
  //     `recent blockhash when sending ${recentBlockhash} ${recentBlockhash.blockhash}`
  //   );
  //   transaction.recentBlockhash = recentBlockhash.blockhash;

  const transactionSignature = await walletState.sendTransaction(
    transaction,
    connection
  );
  //const transactionSignature = await wallet.signTransaction(transaction, [wallet.payer]);
  // const transactionSignature = await connection.sendRawTransaction(
  //   transaction.serialize(),
  //   { skipPreflight: false }
  // );

  return await connection.confirmTransaction(transactionSignature);
}

async function findAssociatedTokenAddress(
  walletAddress: PublicKey,
  tokenMintAddress: PublicKey
): Promise<PublicKey> {
  return (
    await PublicKey.findProgramAddress(
      [
        walletAddress.toBuffer(),
        TOKEN_PROGRAM_ID.toBuffer(),
        tokenMintAddress.toBuffer(),
      ],
      SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
    )
  )[0];
}
